@import '../assets/sanitize.css';
@import './customMediaQueries.css';
@import './propertySets.css';
@import './slick.css';
@import './slick-theme.css';
@import './quill.snow.css';

/* ================ Custom Properties aka CSS variables ================ */

/**
 * These variables are available in global scope through ":root"
 * element (<html> tag). You can use Custom Properties to achieve
 * more dynamic theming by changing the value on the fly with JS:
 * document.documentElement.style.setProperty("--marketplaceColor", '#55AA55');
 *
 * Read more about how to use CSS Custom Properties
 * https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_custom_properties
 */

:root {
  /* ================ Colors ================ */

  --black: hsl(0, 0%, 7%);
  --black-2: hsla(0, 0%, 7%, 0.75);
  --black-3: hsla(0, 0%, 7%, 0.5);
  --black-4: hsla(0, 0%, 7%, 0.25);
  --black-5: hsla(0, 0%, 7%, 0.1);

  --blue: rgb(43, 47, 104);
  --blue-2: hsl(236, 41%, 29%, 0.75);
  --blue-3: hsl(236, 41%, 29%, 0.5);
  --blue-4: hsl(236, 41%, 29%, 0.25);
  --blue-5: hsl(236, 41%, 29%, 0.1);

  --burgundy: rgb(120, 38, 79);
  --burgundy-2: hsl(330, 52%, 31%, 0.75);
  --burgundy-3: hsl(330, 52%, 31%, 0.5);
  --burgundy-4: hsl(330, 52%, 31%, 0.25);
  --burgundy-5: hsl(330, 52%, 31%, 0.1);

  --rose: rgb(180, 137, 162);
  --rose-2: hsl(325, 22%, 62%, 0.75);
  --rose-3: hsl(325, 22%, 62%, 0.5);
  --rose-4: hsl(325, 22%, 62%, 0.25);
  --rose-5: hsl(325, 22%, 62%, 0.1);

  --beige: rgb(242, 241, 239);
  --beige-2: hsl(40, 10%, 94%, 0.75);
  --beige-3: hsl(40, 10%, 94%, 0.5);
  --beige-4: hsl(40, 10%, 94%, 0.25);
  --beige-5: hsl(40, 10%, 94%, 0.1);

  --white: rgb(242, 241, 239);

  --marketplaceColor: hsl(330, 52%, 31%);
  /* hsl(330, 52%, 31%) */
  --marketplaceColorLight: hsl(330, 52%, 41%);
  /* hsl(330, 52%, 41%), +10L from marketplaceColor */
  --marketplaceColorDark: hsl(330, 52%, 11%);
  /* hsl(330, 52%, 11%), -20L from marketplaceColor */

  /* Used with inline CSS SVGs */
  --marketplaceColorEncoded: %23a02cbf;

  --successColor: #2ecc71;
  --successColorDark: #239954;
  --successColorLight: #f0fff6;
  --failColor: #ff0000;
  --failColorLight: #fff0f0;
  --attentionColor: var(--burgundy);
  --attentionColorLight: #fff7f0;
  --bannedColorLight: var(--marketplaceColorLight);
  --bannedColorDark: var(--marketplaceColor);

  --matterColorDark: var(--burgundy);
  --matterColor: var(--blue);
  --matterColorAnti: #b2b2b2;
  --matterColorNegative: #e7e7e7;
  --matterColorBright: #fcfcfc;
  --matterColorLight: #ffffff;

  --matterColorLightTransparent: rgba(255, 255, 255, 0.65);

  /* ================ Font ================ */
  --fontFamily: 'Source Sans Pro', sans-serif;

  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemiBold: 600;
  --fontWeightBold: 700;

  --fontWeightHighlightEmail: var(--fontWeightBold);

  /* ================ Spacing unites ================ */

  /* Multiples of mobile and desktop spacing units should be used with margins and paddings. */
  --spacingUnit: 6px;
  --spacingUnitDesktop: 8px;

  /* Shadows */
  --boxShadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowTop: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  --boxShadowLight: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowPopup: 0 8px 16px 0 rgba(0, 0, 0, 0.3);
  --boxShadowPopupLight: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowBreakdown: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
  --boxShadowButton: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  --boxShadowListingCard: 0 0 50px 0 rgba(0, 0, 0, 0.1);
  --boxShadowNotFoundPageSearch: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  --boxShadowSectionLocationHover: 0 10px 30px 0 rgba(0, 0, 0, 0.1);
  --boxShadowBottomForm: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);
  --boxShadowFilterButton: 0 4px 16px 0 rgba(0, 0, 0, 0.2);

  /* ================ Z-index base levels ================ */

  /* topbar is positioned slightly above content */
  --zIndexTopbar: 10;
  /* small popups on UI should use z-indexes above 50 */
  --zIndexPopup: 50;
  /* modals and UI overlays should use z-indexes above 100 */
  --zIndexModal: 100;
  /* generic error message should overlay even modals */
  --zIndexGenericError: 200;

  /* ================ Border radius ================ */

  --borderRadius: 2px;
  --borderRadiusMobileSearch: 3px;

  /* ================ Transition styles ================ */

  --transitionStyle: ease-in 0.2s;
  --transitionStyleButton: ease-in-out 0.1s;

  /* ================ Topbar related ================ */

  --topbarHeight: 60px;
  --topbarHeightDesktop: 60px;

  --TopbarMobileMenu_topMargin: 96px;

  --Topbar_logoHeight: 25px;
  --CheckoutPage_logoHeight: 25px;
  --CheckoutPage_logoHeightDesktop: 27px;

  --TopbarSearchForm_inputHeight: 53px;
  --TopbarSearchForm_topbarMargin: 94px;
  --TopbarSearchForm_bottomBorder: 3px;

  /* ================ Modal default padding ================ */

  --modalPadding: 24px 24px 48px 24px;
  --modalPaddingMedium: 55px 60px 55px 60px;

  /* ================ Filters ================ */
  --marketplaceButtonSmallDesktopPadding: 9px 16px 9px 16px;

  /* ================ DateInput, DateRangeInput, DateRangeController ================ */
  --ReactDates_selectionHeight: 36px;
  --ReactDates_hoveredOverlayColor: rgba(255, 255, 255, 0.2);

  --DateInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeInput_selectionHeight: var(--ReactDates_selectionHeight);
  --DateRangeInput_hoveredOverlayColor: var(--ReactDates_hoveredOverlayColor);
  --DateRangeController_selectionHeight: var(--ReactDates_selectionHeight);

  /* ================ SectionHero ================ */

  --SectionHero_desktopTitleMaxWidth: 625px;

  /* ================ TabNav ================ */
  --TabNav_linkWidth: 240px;

  /* ================ LandingPage ================ */
  --LandingPage_sectionMarginTop: 40px;
  --LandingPage_sectionMarginTopMedium: 60px;
  --LandingPage_sectionMarginTopLarge: 94px;

  /* ================ EditListingAvailabilityForm, ManageAvailabilityCalendar ================ */
  --ManageAvailabilityCalendar_gridColor: #e0e0e0;
  --ManageAvailabilityCalendar_availableColor: #ffffff;
  --ManageAvailabilityCalendar_availableColorHover: #fafafa;
  --ManageAvailabilityCalendar_blockedColor: #ebebeb;
  --ManageAvailabilityCalendar_blockedColorHover: #e6e6e6;
  --ManageAvailabilityCalendar_reservedColor: #e6fff0;
  --ManageAvailabilityCalendar_reservedColorHover: #e1faeb;
  --ManageAvailabilityCalendar_failedColor: #fff2f2;

  /* ================ ProfileSettingsForm ================ */
  --ProfileSettingsForm_avatarSize: 96px;
  --ProfileSettingsForm_avatarSizeDesktop: 240px;





  /* ====== React toastify css root */
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right,
      #4cd964,
      #5ac8fa,
      #007aff,
      #34aadc,
      #5856d6,
      #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
  /* ====== React toastify css root */
}

/* ================ Global element styles ================ */

body {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  -moz-osx-font-smoothing: grayscale;
  /* http://szafranek.net/blog/2009/02/22/font-smoothing-explained/ */
  text-rendering: optimizeSpeed;

  background-color: var(--matterColorBright);
}

::selection {
  background: var(--marketplaceColor);
  /* WebKit/Blink Browsers */
  color: var(--matterColorLight);
}

::-moz-selection {
  background: var(--marketplaceColor);
  /* Gecko Browsers */
  color: var(--matterColorLight);
}

a {
  @apply --marketplaceLinkStyles;
}

h1 {
  @apply --marketplaceH1FontStyles;
}

h2 {
  @apply --marketplaceH2FontStyles;
}

h3 {
  @apply --marketplaceH3FontStyles;
}

h4 {
  @apply --marketplaceH4FontStyles;
}

h5 {
  @apply --marketplaceH5FontStyles;
}

h6 {
  @apply --marketplaceH6FontStyles;
}

input {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightMedium);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.1px;
  /* No margins for default font */

  @media (--viewportMedium) {
    font-size: 16px;
    line-height: 32px;
  }
}

textarea,
select,
li {
  @apply --marketplaceDefaultFontStyles;
}

p,
pre {
  @apply --marketplaceBodyFontStyles;
}

/* ================ Normalisations ================ */

html {
  @apply --marketplaceDefaultFontStyles;
  color: var(--matterColor);
  padding: 0;
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
}

legend,
label {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightSemiBold);
  display: block;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightSemiBold);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

button {
  font-family: Helvetica, Arial, sans-serif;
}

select {
  @apply --marketplaceSelectStyles;
}

input {
  @apply --marketplaceInputStyles;
}

textarea {
  @apply --marketplaceInputStyles;

  /* Todo: All textareas are using auto-sizing extension which currently calculates required space incorrectly when box-sixing is "border-box" */
  box-sizing: content-box;
  padding: 2px 0 8px 0;

  @media (--viewportMedium) {
    padding: 6px 0 8px 0;

    &::placeholder {
      padding: 0 0 0 0;
    }
  }
}

textarea::placeholder {
  padding: 0 0 0 0;
}

.fontsLoaded body,
.fontsLoaded button {
  font-family: 'Source Sans Pro',
    sans-serif;
}


/* map pop up css */

.mapboxgl-ctrl-group:not(:empty) {
  box-shadow: none !important;
}

/* React toastify css */

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index) px);
  position: fixed;
  padding: 4px;
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast-container {
    width: 100vw;
    padding: 0;
    left: 0;
    margin: 0;
  }

  .Toastify__toast-container--top-left,
  .Toastify__toast-container--top-center,
  .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  position: relative;
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-height: var(--toastify-toast-max-height);
  overflow: hidden;
  font-family: var(--toastify-font-family);
  cursor: pointer;
  direction: ltr;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast-body {
  margin: auto 0;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 6px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.Toastify__toast-body>div:last-child {
  -ms-flex: 1;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  margin-inline-end: 10px;
  width: 20px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -ms-flexbox;
  display: flex;
}

.Toastify--animate {
  animation-fill-mode: both;
  animation-duration: 0.7s;
}

.Toastify--animate-icon {
  animation-fill-mode: both;
  animation-duration: 0.3s;
}

@media only screen and (max-width : 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  background: transparent;
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.3s ease;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

.Toastify__close-button--light {
  color: #000;
  opacity: 0.3;
}

.Toastify__close-button>svg {
  fill: currentColor;
  height: 16px;
  width: 14px;
}

.Toastify__close-button:hover,
.Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: var(--toastify-z-index);
  opacity: 0.7;
  transform-origin: left;
}

.Toastify__progress-bar--animated {
  animation: Toastify__trackProgress linear 1 forwards;
}

.Toastify__progress-bar--controlled {
  transition: transform 0.2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: right;
}

.Toastify__spinner {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 2px solid;
  border-radius: 100%;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  animation: Toastify__spin 0.65s linear infinite;
}

@keyframes Toastify__bounceInRight {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {

  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left,
.Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right,
.Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left,
.Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right,
.Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in;
  }

  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    transform: translate3d(110%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    transform: translate3d(-110%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    transform: translate3d(0, 110%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    transform: translate3d(0, -110%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left,
.Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right,
.Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left,
.Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right,
.Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*# sourceMappingURL=ReactToastify.css.map */
