/* Delete Model */
.modelWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.modelHeading {
  font-family: var(--fontFamily);
  font-size: 24px;
  font-weight: var(--fontWeightSemiBold);
  margin: 0;
}
.modelContent {
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeight);
  text-align: center;
  line-height: 24px;
}
.btnsWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
