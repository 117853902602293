.root {
  width: 100%;
  height: 100%;
  background-color: var(--matterColorNegative);
}

.mapRoot {
  width: 100%;
  height: 100%;
}
.popupInfoWrapper {
  /* padding-right: 15px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popupInfoWrapper h3 {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: var(--fontWeightBold);
  margin: 0;
}
.popupInfoWrapper p {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: var(--fontWeight);
  margin: 0;
  line-height: 20px;
}
.popupInfoLink {
  font-family: var(--fontFamily);
  font-size: 14px;
  font-weight: var(--fontWeight);
}
