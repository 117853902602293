.fieldRoot {
}

.inputRoot {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
}

.selectWrapper {
  flex-basis: calc(33%);
}
.selectWrapper select {
  background-image: none;
}

.select {
  display: inline-block;

  /* Border */
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--attentionColor);
}
.select:hover,
.select:focus {
    border-bottom-color: var(--matterColorDark);
    outline: none;
  }

.notSet {
  color: var(--matterColorAnti);
}

.selectSuccess {
  color: var(--matterColor);
  border-bottom-color: var(--successColor);
}

.selectError {
  border-bottom-color: var(--failColor);
}
