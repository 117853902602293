@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
  width: 100%;
  padding: 5rem 24px;
  background-color: var(--blue);
  position: relative;

  @media (--viewportMedium) {
    padding: 4.5rem 24px;
  }

  @media (--viewportLarge) {
    padding: 5rem 0;
  }
}

.root:before {
  content: '';
  display: block;
  background-image: url('../../assets/sommly-pattern-footer.png');
  background-repeat: repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.05;
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  z-index: 100;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: 20px;
  height: 24px;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.linksWrapper {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-bottom: 18px;
  grid-gap: 20px;
  z-index: 120;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    margin-bottom: 0;
    z-index: 120;
  }
}

.links {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin-bottom: 0;
  }
}

.appLinks {
  /* Layout */
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  margin-bottom: 18px;

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 0;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  /* margin-right: 24px; */
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  /* margin-bottom: 18px; */

  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 0;
  }
}

.logoLink {
  display: block;
  margin-bottom: 14px;
}

.logo {
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
  max-height: 130px;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    object-position: left center;
    margin-bottom: 0;
  }
}


.organizationDescription,
.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  margin-top: 24px;
}

.copyrightLink {
  color: var(--matterColor);
  transition: var(--transitionStyleButton);
}

.copyrightLink:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 165px;
  margin-right: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  /* min-height: 24px;
  margin-bottom: 6px; */

  @media (--viewportMedium) {
    margin-bottom: 0;
  }
}

.listItem h3 {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 25px;
  line-height: 30px;
  color: #FFFFFF;
}

.listItem .emailInput {
  border-bottom: 2px solid var(--white);
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeight);
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
}

.listItem .subscriibeBtn {
  border: none;
  border-radius: 100px;
  padding: 6px 26.5px 7px;
  margin-top: 30px;
  background: var(--rose);

  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--blue);
}

.listItem .subscriibeBtn:hover {
  cursor: pointer;
  background: var(--rose-3);
  color: var(--white);
}

.listItem a {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeight);
  font-size: 16px;
  line-height: 208.2%;
  color: #FFFFFF;
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  line-height: 20px;
  color: var(--white);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.link:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 165px;
  margin-right: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 165px;
  margin-right: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.socialLinks {
  display: flex;
  flex-direction: column;
}

.socialLinks a {
  text-decoration: none !important;
  color: #ffffff;
}

.socialLinks a:hover {
  color: var(--rose);
}

.socialLinks a svg {
  margin-right: 4px;
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  /* flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 109px;
  margin-right: 24px;

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  } */
}

.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.legalLink:hover,
.privacy:hover,
.terms:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;
  line-height: 22px;
  @media (--viewportMedium) {
    line-height: 30px;
  }
}

.organizationCopyrightMobile:hover {
  color: var(--marketplaceColor);
  text-decoration: none;
}

.tosAndPrivacyMobile {
  padding-left: 8px;
  flex-basis: 172px;
}

.privacy {
  margin-right: 24px;
}
