@import '../../../styles/propertySets.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 11%;

  /* fill */
  background-color: var(--white);
}
.rootForImage {

}
.SearchIcon{
  fill: 'none';
  stroke: #78264F;
  rotate: 280deg;
}
/* logo */
.logoLink {
  flex-shrink: 0;
  background-color: var(--burgundy);
  border: 2px solid var(--white);
  padding: 11px;
}

.logo {
  /* If height is increased, change link paddings accordingly */
  height: 70px;

  /* Good aspect ratio should be less than 160/27 (width/height) */
  /* NOTE: extremely wide logos, need special handling. */
  width: auto;
  max-width: 220px;
  object-fit: contain;
  object-position: left center;
}

/* Search */
.searchLink {
  flex-grow: 1;
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--matterColorNegative);

  @media (--viewportLarge) {
    padding-left: 0;
  }
}

.search {
  @apply --marketplaceH4FontStyles;
  @apply --TopbarDesktop_label;
  color: var(--matterColor);
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

.navMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%
}


/* Create listing (CTA for providers) */
.navMenuLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;

  flex-shrink: 0;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  height: 100%;
  padding: 0 1rem;
  color: var(--black);
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
  }
}
.navMenuLink:hover {
  color: var(--burgundy);
}
/* .tabletMenu {
  display: block;
} */
@media screen and (max-width: 1268px) and (min-width: 768px) {
  .tabletMenu {
    display: none;
  }
}
.tabletMenuItem {
   display: none;
}
@media screen and (max-width: 1268px) and (min-width: 768px) {
  .tabletMenuItem {
    display: block;
  }
}


.navMenuLinkText {
  @apply --TopbarDesktop_label;
}

/* Inbox */
.inboxLink {
  @apply --TopbarDesktop_linkHover;
  @apply --marketplaceH5FontStyles;
  height: 100%;
  padding: 0 12px 0 12px;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  color: var(--matterColor);
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
.inboxLink:hover {
  color: var(--matterColorDark);
}
@media screen and (max-width: 1268px) and (min-width: 768px) {
  .inboxLink {
    display: none;
  }
}

.inbox {
  @apply --TopbarDesktop_label;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--failColor);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  @apply --TopbarDesktop_linkHover;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;


}
.profileMenuLabel:active {
    border-bottom: 0;
}

.profileMenuIsOpen:hover {
  border-bottom: 0;
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  @apply --TopbarDesktop_linkHover;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 0 0 12px;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--black);
}
.signupLink:hover {
  color: var(--burgundy);
}
.signupDivider {
  padding: 0 6px;
  font-family: var(--fontFamily);
  font-size: 20px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--burgundy);
}
.loginLink {
  @apply --TopbarDesktop_linkHover;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px 0 0;
  font-family: var(--fontFamily);
  font-size: 16px;
  font-weight: var(--fontWeightSemiBold);
  color: var(--black);
}
.loginLink:hover {
  color: var(--burgundy);
}


/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  @apply --marketplaceH4FontStyles;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--matterColor);
  text-align: left;
  transition: var(--transitionStyleButton);
  @media (--viewportMedium) {
    margin: 0;
  }
}
.profileSettingsLink:hover,
.yourListingsLink:hover {
  color: var(--matterColorDark);
  text-decoration: none;
}
.profileSettingsLink:hover .menuItemBorder,
.yourListingsLink:hover .menuItemBorder {
  width: 6px;
  background-color: var(--marketplaceColor);
}

.logoutButton {
  @apply --marketplaceLinkStyles;
  /* Font is specific to this component */
  @apply --marketplaceH5FontStyles;
  font-size: 14px;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--matterColorAnti);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);
  @media (--viewportMedium) {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
.logoutButton:hover {
  color: var(--matterColorDark);
  text-decoration: none;
}
.logoutButton:hover .menuItemBorder{
  height: 100%;
  width: 6px;
  top: 0;
  background-color: var(--marketplaceColor);
}

.currentPage {
  color: var(--matterColorDark);
  position: relative;
}
.currentPage .menuItemBorder {
  width: 6px;
  background-color: var(--matterColorDark);
}
.currentPage:hover .menuItemBorder {
  background-color: var(--matterColorDark);
}

.navUsers {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.itemsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.winerName {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 10px;
  text-align: center;
  color: var(--blue);
  word-spacing: 1.5px;
  letter-spacing: 0.5px;
  /* margin: 30px 0 16px; */
}
.cartWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cartContainer {
  min-width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--white);
  /* padding: 10px 5px; */

  @media (--viewportMedium) {
    min-width: 70%;
  }
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 15px;
}

.titleWrapper h3 {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 10px;
  /* text-align: center; */
  color: var(--blue);
  word-spacing: 1.5px;
  letter-spacing: 0.5px;
  margin: 13px 0 0;
}

.titleWrapper h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;
  text-align: center;
  color: var(--burgundy);
}


.cardImgWrapper {
  /* width: 100%; */
  height: 115px;
  /* background: #fff; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardImgWrapper img {
  width: 100%;
}
.deleteIcon:hover{
  cursor: pointer;
}
.viewCartButton {
  background: var(--white);
  padding: 6px 30px 7px;
  border: 1px solid var(--matterColor);
  border-radius: 100px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--marketplaceColor);
  cursor: pointer;
  margin-top: 20px;

  width: 100%;
}
.checkoutBtn {
  background: var(--marketplaceColor);
  padding: 6px 30px 7px;
  border: 1px solid var(--white);
  border-radius: 100px;

  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
  color: var(--white);
  cursor: pointer;
  margin-top: 20px;
}