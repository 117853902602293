.root {
  padding: 30px;
  /* border: 2px dashed var(--white); */
  /* border-radius: 4px; */
  cursor: pointer;

  font-family: var(--font-family);
  font-weight: var(--fontWeight);
  /* color: var(--white); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uploadInput {
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
}
