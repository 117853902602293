.youMightAlsoLikeCard {
  position: relative;
}

.favIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.youMightAlsoLikeCardHeader {
  width: 100%;
  height: 234px;
  overflow: hidden;
}

.youMightAlsoLikeCardHeader img {
  width: 100%;
  min-height: 234px;
  height: auto;
}

.youMightAlsoLikeCardBody {
  padding: 15px 0;
  border-bottom: 1px solid rgba(43, 47, 104, 0.25);
}

.youMightAlsoLikeCardBody h2 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: #2B2F68;
  margin: 0 0 8px;
}

.youMightAlsoLikeCardBody h5 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #131313;
  margin: 0;
}

.youMightAlsoLikeCardFooter {
  display: flex;
  align-items: center;
  padding-top: 15px;
}

.youMightAlsoLikeCardFooter p {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #131313;
  margin: 0;
}

.youMightAlsoLikeCardBottleReview {
  display: flex;
  margin-left: 15px;
}
